import React from "react"
import Layout from "../../../components/Layout"
import Section from "../../../components/Section"
import { Container } from "../../../components/Grid"
import { handleAuthentication } from "../../../utils/auth"

const Callback = () => {
  handleAuthentication()
  return (
    <Layout>
      <Section className="oauth-callback">
        <Container>
          <h1>Logging you in...</h1>
        </Container>
      </Section>
    </Layout>
  )
}

export default Callback
